import CustomCarousel from "../components/Carousel"
import DonateSection from "../components/DonateSection"
import Footer from "../components/Footer"
import { useTranslation } from "react-i18next"
import projects2025 from "../data/Projects2025.json"
import ProjectCard from "../components/ProjectCard"
import Counter from "../components/Counter/Counter"
import { Link } from "react-router-dom"
import ScrollToTop from "../scroll-to-top"
import { useEffect } from "react"
import PartnerSlider from "../components/Sliders/PartnerSlider"
import CellebritiesSlider from "../components/Sliders/CellebritiesSlider"
import homeGoals from "../data/homeGoals.json"
import Cooperation from "../components/Sliders/Cooperation"
import Header from "../components/Header/Header"

const Home = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      document.querySelector('.slogan-home').classList.add('fade-up');
    }, 200);
  })

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="header-img">
        <img src="images/header-images/home.webp" alt="" className="w-100 d-none d-sm-block"></img>
        <img src="images/header-images/home_mobile.webp" alt="" className="w-100 d-sm-none"></img>
        <div className="overlay"></div>
        <div className="overlay2"></div>
        <div className="slogan-home text-center">
          <div className="slogan-home-container">
            <div className="slogan-title">{t('slogan').toUpperCase()}</div>
          </div>
        </div>
        <div>
          <Link to="/doniraj" className="text-decoration-none home-donate-btn"><div className="button mx-auto">{t('donate').toUpperCase()}</div></Link>
        </div>
      </div>
      <div className="w-1200 mx-auto">
        <h1 className="text-center my-5 moto"><b>{t('moto')}</b></h1>
        <h5 className="gray-text text-center home-description">{t('hom_description')}</h5>
        <Link to="/o-nama" className="text-decoration-none"><div className="more-about-btn mx-auto my-5">{t('more_about_us').toUpperCase()}</div></Link>
      </div>

      <CustomCarousel />
      <div className="mx-2">
        <div className="row g-0 home-goals">
          {homeGoals.map((item, key) => (
            <div key={key} className="col-6 col-md-4 col-lg-3 col-xl-2 mt-3 mt-xl-0">
              <div className="home-card h-100 super-light-pink-bg mx-2" role="button">
                <img className="card-img-top w-100" src={`images/homeGoals/${item.image}.webp`} alt=""></img>
                <div className="card-body d-flex flex-column justify-content-between">
                  <div>
                    <h5 className="card-title pink-text">{t(item.title).toUpperCase()}</h5>
                    <p className="card-text" dangerouslySetInnerHTML={{ __html: t(item.text) }}></p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="home-founders-word mx-3 mx-md-5 my-5">
        <div className="row g-0">
          <div className="col-12 col-md-6 text-center mb-5">
            <div className="content text-center">
              <div className="px-3 text-center">
                <h1 className="title">{t('foundation_word_title')}</h1>
                <p className="text text-justify mt-5 mb-4">{t('foundation_word_text')}</p>
                <div>
                  <Link to="/rec-osnivaca" className="text-decoration-none"><div className="button mx-auto">{t('read_more')}</div></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="img-container text-center">
              <img src="/images/other/foundation-word.jpg" alt=""></img>
            </div>
          </div>
        </div>
      </div>
      <Counter />
      <div className="my-5">
        <h1 className="text-center py-5 px-3">{t('cooperation')}</h1>
        <Cooperation />
      </div>
      <div>
        <div className="w-1200 row g-0 mx-auto mb-5">
          <h1 className="text-center my-5">{t('current_news')}</h1>
          {projects2025.slice(0, 3).map((item, key) => (
            <div className="col-12 col-sm-6 col-md-4 mt-3 mt-md-0" key={key}>
              <div className="mx-3 h-100">
                <ProjectCard url="/projekti-2025/podrska-udruzenjima" data={item} key={key} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="super-light-pink-bg">
        <h1 className="text-center py-5">{t('our_partners')}</h1>
        <PartnerSlider />
      </div>
      <div>
        <h1 className="text-center py-5">{t('supported_by')}</h1>
        <CellebritiesSlider />
      </div>
      <DonateSection />
      <Footer />
    </>
  )
}
export default Home